
import {
    defineComponent, defineAsyncComponent, computed
} from 'vue';
import { TabsData } from '@/views/distributor/third-party-device/types/common';
import router, { distributorBaseRouter, distributorSubRouter } from '@/router';
import { isCNServer } from '@/util/location';

export default defineComponent({
    props: {
        type: {
            type: String,
            default: 'project'
        },
        deviceType: {
            type: String,
            default: isCNServer() ? 'control' : 'camera'
        }
    },
    setup(props) {
        const activeName = computed(() => props.deviceType);
        const baseTabs = {
            control: {
                label: WordList.ControlPanel,
                name: 'control',
                component: defineAsyncComponent(() => import('@/views/distributor/third-party-device/components/project-device-control.vue'))
            },
            camera: {
                label: WordList.Camera,
                name: 'camera',
                component: defineAsyncComponent(() => import('@/views/distributor/third-party-device/components/project-device-camera.vue'))
            }
        };
        let tabsData: TabsData = { camera: baseTabs.camera };
        if (isCNServer()) {
            tabsData = baseTabs;
        }

        const changeTab = (name: any) => {
            router.push(`/${distributorBaseRouter}/${distributorSubRouter.thirdPartyDevice}?type=${props.type}&deviceType=${name.paneName}`);
        };
        return {
            tabsData,
            activeName,
            changeTab
        };
    }
});
